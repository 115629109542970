import { useState } from "react";
import { graphql, useMutation } from "react-relay";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import { toast, toastClearWaitingQueue } from "@olivahealth/oli-ui";

import { ResourceTrackingProperties } from "../../../types/tracking";
import { useAmplitude } from "../../../services/contexts/AmplitudeContext";
import useTranslation from "../../../hooks/useTranslation";
import { OlivaHook } from "../../../hooks/OlivaHook";
import {
  useMarkAsFavouriteButtonAddMutation as IAddMutation,
  useMarkAsFavouriteButtonAddMutation$data as IAddMutationData,
} from "./__generated__/useMarkAsFavouriteButtonAddMutation.graphql";
import {
  useMarkAsFavouriteButtonRemoveMutation as IRemoveMutation,
  useMarkAsFavouriteButtonRemoveMutation$data as IRemoveMutationData,
} from "./__generated__/useMarkAsFavouriteButtonRemoveMutation.graphql";

const useMarkAsFavouriteButtonAddMutation = graphql`
  mutation useMarkAsFavouriteButtonAddMutation($resourceId: ID!) {
    addResourceToFavourites(resourceId: $resourceId) {
      __typename
      ... on ResourceFavourited {
        resourceId
      }
    }
  }
`;

const useMarkAsFavouriteButtonRemoveMutation = graphql`
  mutation useMarkAsFavouriteButtonRemoveMutation($resourceId: ID!) {
    removeResourceFromFavourites(resourceId: $resourceId) {
      __typename
      ... on ResourceUnfavourited {
        resourceId
      }
    }
  }
`;

interface UseMarkAsFavouriteButtonEffects {
  toggleFavouriteStatus: (e?) => void;
}

interface UseMarkAsFavouriteButtonData {
  isFavourite: boolean;
}

type UseMarkAsFavouriteButton = OlivaHook<
  UseMarkAsFavouriteButtonData,
  UseMarkAsFavouriteButtonEffects
>;

export default function useMarkAsFavouriteButton({
  initialStatus,
  resourceId,
  trackingProperties,
}: {
  initialStatus: boolean;
  resourceId: string;
  trackingProperties?: ResourceTrackingProperties;
}): UseMarkAsFavouriteButton {
  const { trackEvent } = useAmplitude();
  const [markAsFavourite] = useMutation<IAddMutation>(
    useMarkAsFavouriteButtonAddMutation,
  );
  const { t } = useTranslation("common", {
    keyPrefix: "markAsFavouriteButton",
  });
  const [isFavourite, setIsFavourite] = useState<boolean>(initialStatus);

  const [removeFromFavourite] = useMutation<IRemoveMutation>(
    useMarkAsFavouriteButtonRemoveMutation,
  );

  const toggleFavouriteStatus = (e?: Event) => {
    e?.preventDefault();

    if (isFavourite) {
      setIsFavourite(false);
      removeFromFavourite({
        variables: { resourceId },
        // Rolling back on error
        onCompleted: (data) => {
          toastClearWaitingQueue();
          const hasError =
            data.removeResourceFromFavourites?.__typename !==
            "ResourceUnfavourited";

          if (hasError) {
            setIsFavourite(true);
          }
          toast({
            variant: hasError ? "error" : "success",
            body: t(hasError ? "remove.error" : "remove.success"),
          });

          trackEvent("Content removed from favourites", trackingProperties);
        },
        updater: modifyLocalDataWithLatestUpdate,
      });
    } else {
      setIsFavourite(true);
      markAsFavourite({
        variables: { resourceId },
        onCompleted: (data) => {
          toastClearWaitingQueue();
          const hasError =
            data.addResourceToFavourites?.__typename !== "ResourceFavourited";

          if (hasError) {
            setIsFavourite(false);
          }
          toast({
            variant: hasError ? "error" : "success",
            body: t(hasError ? "add.error" : "add.success"),
          });

          trackEvent("Content added to favourites", trackingProperties);
        },
        updater: modifyLocalDataWithLatestUpdate,
      });
    }
  };

  return {
    status: "success",
    data: {
      isFavourite,
    },
    effects: {
      toggleFavouriteStatus,
    },
  };
}

// Adds and removes the data to the local store's favourite resources
function modifyLocalDataWithLatestUpdate(
  store: RecordSourceSelectorProxy<IAddMutationData | IRemoveMutationData>,
  data: IAddMutationData | IRemoveMutationData,
) {
  const connectionRecord = store.get("client:root:favouriteResources");

  if (!connectionRecord) {
    return;
  }

  function isAddMutation(
    data: IAddMutationData | IRemoveMutationData,
  ): data is IAddMutationData {
    return (data as any).addResourceToFavourites !== undefined;
  }

  if (isAddMutation(data)) {
    const resourceId =
      data.addResourceToFavourites.__typename === "ResourceFavourited" &&
      data.addResourceToFavourites?.resourceId;

    const resourceRecord = resourceId ? store.get(resourceId) : null;

    if (resourceId && resourceRecord) {
      resourceRecord.setValue(true, "isFavourite");
      const newEdge = ConnectionHandler.createEdge(
        store,
        connectionRecord,
        resourceRecord,
        "Resource",
      );
      ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
    }
  } else {
    const resourceId =
      data.removeResourceFromFavourites.__typename === "ResourceUnfavourited" &&
      data.removeResourceFromFavourites?.resourceId;
    const resourceRecord = resourceId ? store.get(resourceId) : null;

    if (resourceId && resourceRecord) {
      resourceRecord.setValue(false, "isFavourite");
      ConnectionHandler.deleteNode(connectionRecord, resourceId);
    }
  }
}
