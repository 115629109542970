import { WORKSHOP_FEW_SPOTS_COUNT } from "@olivahealth/constants";
import { Button, CheckIcon, Text } from "@olivahealth/oli-ui";
import WorkshopType from "@olivahealth/graphql-server/src/domain/value-objects/WorkshopType";
import cx from "classnames";
import useTranslation from "../../../hooks/useTranslation";

interface StatusRowContent {
  buttonAction?: () => void;
  ctaKey: string;
  hasJoinedWaitingList: boolean;
  isAttending: boolean;
  isFullyBooked: boolean;
  statusKey: string | null;
  type?: WorkshopType;
  variant: "default" | "large" | "featured";
}

export default function StatusRowContent({
  buttonAction,
  ctaKey,
  hasJoinedWaitingList,
  isAttending,
  isFullyBooked,
  statusKey,
  type,
  variant,
}: StatusRowContent) {
  const { t } = useTranslation("classes", { keyPrefix: "classCard" });

  if (hasJoinedWaitingList) {
    return (
      <div
        className={cx("flex", {
          "justify-end": variant !== "featured",
          "justify-start": variant === "featured",
        })}
      >
        <Text weight="medium">{t("youAreOnTheList")}</Text>
        <CheckIcon className="ml-2" />
      </div>
    );
  }

  if (isAttending) {
    return (
      <div
        className={cx("flex", {
          "justify-end": variant !== "featured",
          "justify-start": variant === "featured",
        })}
      >
        <Text weight="medium">{t("youAreIn")}</Text>
        <CheckIcon className="ml-2" />
      </div>
    );
  }

  return (
    <div className={buildStyles(statusKey, ctaKey, variant, type)}>
      {statusKey ? (
        <span
          className={cx({
            "text-orange-500": isFullyBooked,
          })}
        >
          {t(statusKey)}
        </span>
      ) : (
        type === WorkshopType.TALK && (
          <span className="text-sm text-neutral-600">{t("liveTalkLabel")}</span>
        )
      )}
      {variant !== "featured"
        ? ctaKey && (
            <Button variant={"secondary"} onClick={buttonAction}>
              {t(ctaKey)}
            </Button>
          )
        : ctaKey && (
            <Button variant="primaryInverse" onClick={buttonAction}>
              {t(ctaKey)}
            </Button>
          )}
    </div>
  );
}

function buildStyles(
  statusKey: string | null,
  ctaKey: string,
  variant: "default" | "large" | "featured",
  type?: WorkshopType,
): string {
  if (variant === "featured") {
    if (statusKey && ctaKey) return "flex items-center gap-4";
    if (!statusKey && ctaKey) return "flex";
  } else {
    if ((statusKey || type) && ctaKey)
      return "flex items-center justify-between";
    if (!statusKey && ctaKey) return "flex justify-end";
  }

  return "";
}

interface Event {
  availableSlots?: number | null;
}

/**
 * Returns the useTranslation() key for the status badge of the status row.
 */
export function getStatusKey(event?: Event | null): string | null {
  if (typeof event?.availableSlots !== "number") {
    return null;
  }

  const hasFewSpots = event.availableSlots <= WORKSHOP_FEW_SPOTS_COUNT;
  const isFullyBooked = event.availableSlots === 0;

  if (isFullyBooked) {
    return "fullyBooked";
  } else if (hasFewSpots) {
    return "fewSpotsLeft";
  }

  return null;
}

/**
 * Returns the useTranslation() key for the CTA button of the status row.
 */
export function getCtaKey(events: readonly Event[]): string {
  const allBooked = events.every((event) => event?.availableSlots === 0);

  if (events.length === 0 || allBooked) {
    return "joinWaitingList";
  } else if (events.length === 1) {
    return "bookNow";
  }

  return "seeAllDates";
}
