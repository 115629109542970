import cx from "classnames";
import { useRouter } from "next/router";
import Link from "next/link";
import ResourceType from "@olivahealth/graphql-server/src/domain/value-objects/ResourceType";
import WorkshopType from "@olivahealth/graphql-server/src/domain/value-objects/WorkshopType";
import { Card, Tag, Text } from "@olivahealth/oli-ui";
import ResourceArea from "@olivahealth/graphql-server/src/domain/value-objects/ResourceArea";

import { getResourceTrackingProperties } from "../../../utils/tracking";

import Image from "../Image";
import StatusRowContent, { getCtaKey, getStatusKey } from "../StatusRowContent";
import useTranslation from "../../../hooks/useTranslation";
import { formatShortDate } from "../../../utils/date/format";
import MarkAsFavouriteButton from "../../../rebrand/organisms/MarkAsFavouriteButton";
import useResourceCard from "./useResourceCard";
import { useResourceCard_data$key as IFragment } from "./__generated__/useResourceCard_data.graphql";

import * as s from "./styles";

type sizeOverride = "sm" | "md" | "lg";

interface Props {
  item: IFragment;
  imageHeightOverride?: sizeOverride;
  imageWidthOverride?: sizeOverride;
  isFavouriteButtonAvailable?: boolean;
}

export default function ResourceCard({
  item,
  imageHeightOverride,
  imageWidthOverride,
  isFavouriteButtonAvailable = true,
}: Props) {
  const { t } = useTranslation("selfCare", { keyPrefix: "card" });
  const { push } = useRouter();

  const {
    data: {
      name,
      duration,
      id,
      event,
      hasJoinedWaitingList,
      showMarkAsFavouriteButton,
      isFavourite,
      image,
      isLiveTalk,
      slug,
      type,
      area,
    },
  } = useResourceCard(item);

  const trackingProperties = getResourceTrackingProperties({
    id,
    name,
    type,
    area: (area || []) as ResourceArea[],
  });

  const resourceUrl = `${getResourceUrlPrefix(isLiveTalk, type)}/${slug}`;

  return (
    <div
      className={cx("h-full", {
        "md:col-span-2": imageWidthOverride === "md",
        "md:col-span-3": imageWidthOverride === "lg",
      })}
      data-testid={`resource-card-${name}`}
    >
      <Card fullHeight animateOnHover>
        <Link href={resourceUrl} passHref>
          <div
            className={cx("relative", {
              "h-36": imageHeightOverride === "sm",
              "h-36 lg:h-44":
                !imageHeightOverride || imageHeightOverride === "md",
              "h-64": imageHeightOverride === "lg",
            })}
          >
            <Image
              src={image}
              alt={`image for ${name}`}
              className="object-cover"
              fill
              sizes="100%"
            />
            {event?.startDate && (
              <span className="absolute left-4 top-5 z-10">
                <Tag variant="info">{formatShortDate(event.startDate)}</Tag>
              </span>
            )}
          </div>
          <div className={s.cardBottom}>
            <div className="mb-2 flex items-center justify-between">
              <span className="flex gap-x-4">
                <Tag>
                  {isLiveTalk ? t("talkBadgeLabel") : t(`typeTag.${type}`)}
                </Tag>
                {duration && <Tag>{duration}</Tag>}
              </span>
              {showMarkAsFavouriteButton && isFavouriteButtonAvailable && (
                <MarkAsFavouriteButton
                  resourceId={id}
                  initialStatus={isFavourite}
                  trackingProperties={trackingProperties}
                />
              )}
            </div>
            <Text gutter="sm">{name}</Text>

            {event && (
              <StatusRowContent
                buttonAction={() => push(resourceUrl)}
                ctaKey={getCtaKey([event])}
                hasJoinedWaitingList={hasJoinedWaitingList ?? false}
                isAttending={event.isUserAttending ?? false}
                isFullyBooked={event.availableSlots === 0}
                statusKey={getStatusKey(event)}
                variant="default"
                type={type as WorkshopType}
              />
            )}
          </div>
        </Link>
      </Card>
    </div>
  );
}

export function getResourceUrlPrefix(
  isLiveTalk: boolean,
  resourceType: string,
): string {
  const organisationResourceTypes = [
    ResourceType.ASSET,
    ResourceType.CONTENT,
  ] as ReadonlyArray<ResourceType>;

  if (isLiveTalk) {
    return "talks";
  }

  return organisationResourceTypes.includes(resourceType as ResourceType)
    ? "/organisation/resources"
    : "/practices";
}
