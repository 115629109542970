import cx from "classnames";
import { Button, HeartIcon, Text } from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";

import { ResourceTrackingProperties } from "../../../types/tracking";
import useTranslation from "../../../hooks/useTranslation";
import useMarkAsFavouriteButton from "./useMarkAsFavouriteButton";

interface IProps {
  resourceId: string;
  initialStatus?: boolean;
  variant?: "full" | "icon" | "button";
  trackingProperties?: ResourceTrackingProperties;
}

export default function MarkAsFavouriteButton({
  resourceId,
  initialStatus = false,
  variant = "icon",
  trackingProperties,
}: IProps) {
  const {
    data: { isFavourite },
    effects: { toggleFavouriteStatus },
  } = useMarkAsFavouriteButton({
    resourceId,
    initialStatus,
    trackingProperties,
  });

  const { t } = useTranslation("common", {
    keyPrefix: "markAsFavouriteButton",
  });

  const styledHeartIcon = (
    <HeartIcon
      size={22}
      className={cx({
        "transform group-hover:scale-110": variant !== "button",
        "mr-2": variant === "full",
      })}
      color={tokens.colors["neutral"][900]}
      variant={isFavourite ? "filled" : "outline"}
    />
  );

  if (variant === "button") {
    return (
      <Button
        iconLeft={styledHeartIcon}
        variant="secondary"
        onClick={toggleFavouriteStatus}
        width="full"
      >
        {t(isFavourite ? "title.favourited" : "title.add")}
      </Button>
    );
  }

  return (
    <button onClick={(e) => toggleFavouriteStatus(e)} className="group rebrand">
      {styledHeartIcon}
      {variant === "full" && (
        <Text color="white">
          {t(isFavourite ? "title.remove" : "title.add")}
        </Text>
      )}
    </button>
  );
}
