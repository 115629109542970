/**
 * @generated SignedSource<<f861289833535b6e9878b177ad72a284>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useMarkAsFavouriteButtonAddMutation$variables = {
  resourceId: string;
};
export type useMarkAsFavouriteButtonAddMutation$data = {
  readonly addResourceToFavourites: {
    readonly __typename: "ResourceFavourited";
    readonly resourceId: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useMarkAsFavouriteButtonAddMutation = {
  response: useMarkAsFavouriteButtonAddMutation$data;
  variables: useMarkAsFavouriteButtonAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "resourceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "resourceId",
        "variableName": "resourceId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "addResourceToFavourites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceId",
            "storageKey": null
          }
        ],
        "type": "ResourceFavourited",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMarkAsFavouriteButtonAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMarkAsFavouriteButtonAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d9157afaab60c065a7b86b71c588c365",
    "id": null,
    "metadata": {},
    "name": "useMarkAsFavouriteButtonAddMutation",
    "operationKind": "mutation",
    "text": "mutation useMarkAsFavouriteButtonAddMutation(\n  $resourceId: ID!\n) {\n  addResourceToFavourites(resourceId: $resourceId) {\n    __typename\n    ... on ResourceFavourited {\n      resourceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4216dfe74f4a4ed310ff22987552e3f6";

export default node;
