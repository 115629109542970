/**
 * @generated SignedSource<<f2e8707f1f37c6fede79f7fda70316db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ResourceArea = "FITNESS" | "FOCUS" | "LEARN" | "LISTEN" | "MANAGE" | "MEDITATION" | "SLEEP" | "YOGA";
export type ResourceType = "ARTICLE" | "ASSET" | "AUDIO" | "CLASS" | "CONTENT" | "COURSE" | "PODCAST" | "TALK" | "VIDEO" | "WORKSHEET";
import { FragmentRefs } from "relay-runtime";
export type useResourceCard_data$data = {
  readonly area?: ReadonlyArray<ResourceArea>;
  readonly createdAt?: string;
  readonly events?: ReadonlyArray<{
    readonly availableSlots: number | null | undefined;
    readonly durationInMinutes: number;
    readonly id: string;
    readonly isUserAttending: boolean;
    readonly startDate: string;
  }>;
  readonly format?: string | null | undefined;
  readonly hasJoinedWaitingList?: boolean;
  readonly id: string;
  readonly illustrationCard?: string;
  readonly imageCard?: string | null | undefined;
  readonly isFavourite?: boolean;
  readonly name?: string;
  readonly slug: string;
  readonly subtitle?: string;
  readonly type?: ResourceType;
  readonly " $fragmentType": "useResourceCard_data";
};
export type useResourceCard_data$key = {
  readonly " $data"?: useResourceCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useResourceCard_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useResourceCard_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "illustrationCard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupSession",
          "kind": "LinkedField",
          "name": "events",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "durationInMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "availableSlots",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isUserAttending",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasJoinedWaitingList",
          "storageKey": null
        }
      ],
      "type": "Workshop",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "format",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageCard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFavourite",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        }
      ],
      "type": "Resource",
      "abstractKey": null
    }
  ],
  "type": "ResourceInterface",
  "abstractKey": "__isResourceInterface"
};
})();

(node as any).hash = "fc69f53b6604a75809ff81d1e0934942";

export default node;
