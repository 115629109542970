import { graphql, useFragment } from "react-relay";
import { endOfDay, subDays } from "date-fns";
import WorkshopType from "@olivahealth/graphql-server/src/domain/value-objects/WorkshopType";
import ResourceArea from "@olivahealth/graphql-server/src/domain/value-objects/ResourceArea";
import { NEW_RESOURCE_THRESHOLD_IN_DAYS } from "@olivahealth/constants";

import { OlivaHook } from "../../../hooks/OlivaHook";
import useTranslation, { TFunction } from "../../../hooks/useTranslation";
import {
  useResourceCard_data$key as IFragment,
  useResourceCard_data$data as FragmentData,
} from "./__generated__/useResourceCard_data.graphql";

const ResourceCardFragment = graphql`
  fragment useResourceCard_data on ResourceInterface {
    id
    slug
    ... on Workshop {
      createdAt
      subtitle
      illustrationCard
      events {
        id
        durationInMinutes
        availableSlots
        isUserAttending
        startDate
      }
      hasJoinedWaitingList
    }
    ... on Resource {
      createdAt
      name
      type
      format
      imageCard
      isFavourite
      area
    }
  }
`;

interface Event {
  id: string;
  durationInMinutes: number;
  availableSlots?: number | null;
  isUserAttending: boolean;
  startDate: string;
}

export interface UseResourceCardData {
  id: string;
  name: string;
  duration: string | null;
  event?: Event | null;
  hasJoinedWaitingList?: boolean;
  showMarkAsFavouriteButton: boolean;
  image: string;
  isLiveTalk: boolean;
  slug: string;
  isFavourite: boolean;
  type: string;
  isNew: boolean;
  area?: ReadonlyArray<ResourceArea>;
}

type UseResourceCard = OlivaHook<UseResourceCardData>;

export default function useResourceCard(data: IFragment): UseResourceCard {
  const { t } = useTranslation("common", { keyPrefix: "eventCard" });
  const fragmentData = useFragment<IFragment>(ResourceCardFragment, data);

  const item = mapToItem(fragmentData, t);

  return {
    status: "success",
    data: item,
    effects: {},
  };
}

function mapToItem(item: FragmentData, t: TFunction): UseResourceCardData {
  const {
    id,
    createdAt,
    slug,
    subtitle,
    name,
    events,
    format,
    hasJoinedWaitingList,
    illustrationCard,
    imageCard,
    type,
    isFavourite,
    area,
  } = item;

  const createdAtDate = createdAt ? new Date(createdAt) : null;

  if (events) {
    // item is workshop
    return {
      id,
      name: subtitle ?? "",
      duration: getWorkshopDuration(events[0], t),
      isFavourite: false,
      showMarkAsFavouriteButton: false,
      event: events[0] ?? null,
      hasJoinedWaitingList: hasJoinedWaitingList ?? false,
      image: illustrationCard ?? "/img/img_card_placeholder.jpg",
      isLiveTalk: true,
      slug,
      type: WorkshopType.TALK,
      isNew: createdAtDate
        ? createdAtDate >
          endOfDay(subDays(new Date(), NEW_RESOURCE_THRESHOLD_IN_DAYS))
        : false,
    };
  }

  // item is resource
  return {
    id,
    name: name ?? "",
    isFavourite: isFavourite ?? false,
    showMarkAsFavouriteButton: true,
    duration: format ?? null,
    image: imageCard ?? "/img/img_card_placeholder.jpg",
    isLiveTalk: false,
    slug,
    type: type ?? "",
    isNew: createdAtDate
      ? createdAtDate > endOfDay(subDays(new Date(), 14))
      : false,
    area: area || [],
  };
}

function getWorkshopDuration(event: Event, t: TFunction): string | null {
  if (!event?.durationInMinutes) {
    return null;
  }

  return t("duration", { duration: event.durationInMinutes });
}
