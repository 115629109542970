import ResourceType from "@olivahealth/graphql-server/src/domain/value-objects/ResourceType";
import WorkshopType from "@olivahealth/graphql-server/src/domain/value-objects/WorkshopType";
import ResourceArea from "@olivahealth/graphql-server/src/domain/value-objects/ResourceArea";

import { ResourceTrackingProperties } from "../../types/tracking";

/**
 * We don't have a clear definition of which content comes from Sanctuary and which comes from Oliva,
 * so we are going to assume that all "AUDIO" and "VIDEO" content are from Sanctuary and all other
 * types are from Oliva.
 */
export function getContentProviderFromResourceType(
  type: ResourceType | string | undefined,
) {
  return type === ResourceType.AUDIO || type === ResourceType.VIDEO
    ? "SANCTUARY"
    : "OLIVA";
}

const RESOURCES_WITH_PLAYBACK_TYPES = [
  ResourceType.AUDIO,
  ResourceType.PODCAST,
  ResourceType.TALK,
  ResourceType.VIDEO,
] as ResourceType[];

export function contentHasPlayback(type: ResourceType) {
  return RESOURCES_WITH_PLAYBACK_TYPES.includes(type);
}

interface IGetResourceTrackingProperties {
  id?: string;
  name?: string;
  type?: ResourceType | WorkshopType | string;
  area: ResourceArea[];
}

export function getResourceTrackingProperties({
  id = "",
  name = "",
  type = "",
  area = [],
}: IGetResourceTrackingProperties): ResourceTrackingProperties {
  return {
    contentId: id,
    contentName: name,
    contentType: type,
    contentProvider: getContentProviderFromResourceType(type),
    contentArea:
      type === WorkshopType.TALK
        ? [WorkshopType.TALK]
        : (area as ResourceArea[]),
    hasPlayback: contentHasPlayback(type as ResourceType),
  };
}
