/**
 * @generated SignedSource<<57ff943c2ff1f29d94c32c59bd6ed32c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useMarkAsFavouriteButtonRemoveMutation$variables = {
  resourceId: string;
};
export type useMarkAsFavouriteButtonRemoveMutation$data = {
  readonly removeResourceFromFavourites: {
    readonly __typename: "ResourceUnfavourited";
    readonly resourceId: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useMarkAsFavouriteButtonRemoveMutation = {
  response: useMarkAsFavouriteButtonRemoveMutation$data;
  variables: useMarkAsFavouriteButtonRemoveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "resourceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "resourceId",
        "variableName": "resourceId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "removeResourceFromFavourites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceId",
            "storageKey": null
          }
        ],
        "type": "ResourceUnfavourited",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMarkAsFavouriteButtonRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMarkAsFavouriteButtonRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d335db983ceebefc6debaf22d3c685ff",
    "id": null,
    "metadata": {},
    "name": "useMarkAsFavouriteButtonRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation useMarkAsFavouriteButtonRemoveMutation(\n  $resourceId: ID!\n) {\n  removeResourceFromFavourites(resourceId: $resourceId) {\n    __typename\n    ... on ResourceUnfavourited {\n      resourceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "472fdd23e993018d460f68fc48b1d574";

export default node;
